export const EMAILS = {
  WU_CUSTOMER_SERVICE: 'winfieldcustomerservice@landolakes.com',
} as const;

export const URLS = {
  LOL: 'https://www.landolakesinc.com/',
  LOL_CALIFORNIA_SUPPLY_CHAINS_ACT:
    'https://www.landolakesinc.com/california-supply-chains-act',
  LOL_PRIVACY_POLICY: 'https://www.landolakesinc.com/Privacy-Policy',
  WINFIELD_UNITED: 'https://www.winfieldunited.com',
  WU_FACEBOOK: 'https://www.facebook.com/WinFieldUnited',
  WU_YOUTUBE: 'https://www.youtube.com/user/WinfieldSolutions',
  WU_LINKEDIN: 'https://www.linkedin.com/company/winfield-solutions',
} as const;
