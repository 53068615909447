import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { AuthenticatedTemplate } from '@azure/msal-react';

import { Box, Grid, styled, useMediaQuery, useTheme } from '@atlas-ui/core';

import AppHeaderMobile from './AppHeaderMobile';

import AiSearchBar from '../SearchBar/AiSearchBar';
import UserAccount from '../UserAccount/UserAccount';

const StyledAppHeaderContainer = styled(Box)(({ theme }) => ({
  margin: '0 40px',
  [theme.breakpoints.down('sm')]: {
    margin: 0,
    paddingLeft: 0,
  },
}));

const StyledHeader = styled(Grid)({
  maxWidth: '1728px',
  margin: '0 auto',
  boxShadow: 'calc(-0.5*100vw) 0 0 0 #0B3F54, calc(0.5*100vw) 0 0 0 #144E65',
  position: 'relative',
  height: '80px',
  backgroundColor: '#0B3F54',
  '@media print': {
    display: 'none',
  },
  '& .logo-img__container': {
    cursor: 'pointer',
  },
}) as typeof Grid;

interface IAppHeaderProps {
  showPortalNavigation: boolean;
  setShowPortalNavigation: React.Dispatch<React.SetStateAction<boolean>>;
}

const AppHeader = ({
  showPortalNavigation,
  setShowPortalNavigation,
}: IAppHeaderProps): React.ReactElement => {
  const history = useHistory();
  const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'));
  const { pathname } = useLocation();

  useEffect(() => {
    if (isMobile) {
      setShowPortalNavigation(false);
    }
  }, [isMobile, pathname, setShowPortalNavigation]);

  const redirectToHome = () => {
    history.push('/');
  };

  return (
    <StyledAppHeaderContainer id="wu-app_header-container">
      {isMobile ? (
        <AppHeaderMobile
          showPortalNavigation={showPortalNavigation}
          setShowPortalNavigation={setShowPortalNavigation}
        />
      ) : (
        <StyledHeader
          container
          alignItems="center"
          role="banner"
          data-testid="app-header"
          justifyContent="space-between"
          flexWrap="nowrap">
          <Grid
            container
            alignItems="center"
            flexBasis="content"
            flexWrap="nowrap">
            <Box
              onClick={redirectToHome}
              className="logo-img__container"
              data-testid="wf-united-logo-container">
              <img
                src="/images/winfield-logo.png"
                alt="WinField United Logo"
                height={40}
                width={205}
              />
            </Box>
          </Grid>
          <AuthenticatedTemplate>
            <AiSearchBar />
            <UserAccount />
          </AuthenticatedTemplate>
        </StyledHeader>
      )}
    </StyledAppHeaderContainer>
  );
};

export default AppHeader;
