import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import AuthProtectedRoutes from './AuthProtectedRoutes';
import PublicRoutes, { publicRoutesConfig } from './PublicRoutes';

const RouteManager = () => {
  const { pathname } = useLocation();

  const isPublicRoute = useMemo(() => {
    return Object.values(publicRoutesConfig).some(route => {
      if (route.exact) {
        return route.path === pathname;
      } else {
        /* istanbul ignore next */ // This block of code is currently unreachable
        return pathname.includes(route.path);
      }
    });
  }, [pathname]);

  return isPublicRoute ? <PublicRoutes /> : <AuthProtectedRoutes />;
};

export default RouteManager;
