import React from 'react';

import { AuthenticatedTemplate } from '@azure/msal-react';

import { Box, Grid, Icon, styled } from '@atlas-ui/core';

import UserAccount from '../UserAccount/UserAccount';

interface IAdditionalProps {
  showPortalNavigation: boolean;
}

const StyledHeader = styled(Grid)<IAdditionalProps>(
  ({ showPortalNavigation }) => ({
    position: showPortalNavigation ? 'fixed' : 'relative',
    height: '52px',
    backgroundColor: '#0A0A0A',
    zIndex: 101,
    '@media print': {
      display: 'none',
    },
    '& .logo-img__container': {
      margin: '0 20px',
    },
  }),
);

const StyledMenuIcon = styled(Icon)({
  color: '#FFFFFF',
  cursor: 'pointer',
  marginLeft: '14px',
  '&.fa-lg': {
    fontSize: '20px',
    lineHeight: '20px',
  },
}) as typeof Icon;

interface IAppHeaderMobileProps {
  showPortalNavigation: boolean;
  setShowPortalNavigation: React.Dispatch<React.SetStateAction<boolean>>;
}

const AppHeaderMobile = ({
  showPortalNavigation,
  setShowPortalNavigation,
}: IAppHeaderMobileProps): React.ReactElement => {
  return (
    <StyledHeader
      id="wu-app_header-mobile"
      container
      alignItems="center"
      role="banner"
      data-testid="app-header"
      justifyContent="space-between"
      flexWrap="nowrap"
      showPortalNavigation={showPortalNavigation}>
      <Grid
        container
        item
        alignItems="center"
        flexBasis="content"
        flexWrap="nowrap">
        <StyledMenuIcon
          className={`fa fa-lg ${showPortalNavigation ? 'fa-close' : 'fa-nav-menu-fill'}`}
          onClick={() => setShowPortalNavigation((prev: boolean) => !prev)}
          data-testid="menu-icon"
        />
        <Box className="logo-img__container">
          <img
            src="/images/winfield-logo.png"
            alt="WinField United Logo"
            height={20}
            width={84}
          />
        </Box>
      </Grid>
      <AuthenticatedTemplate>
        <UserAccount />
      </AuthenticatedTemplate>
    </StyledHeader>
  );
};

export default AppHeaderMobile;
