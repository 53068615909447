import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';

import { InteractionType } from '@azure/msal-browser';
import {
  AuthenticatedTemplate,
  useIsAuthenticated,
  useMsal,
  useMsalAuthentication,
} from '@azure/msal-react';

import { Loader, Page404 } from '@wu-ui/common';

import { useAppContext } from '../contexts/AppContext';

import { APP_ROUTE_MAIN_PATHS } from '../utils/appRoutes';

const Dashboard = React.lazy(() => import('../pages/Dashboard'));
const SearchResults = React.lazy(() => import('../pages/SearchResults'));
const Catalyst = React.lazy(() => import('../pages/Catalyst'));
const Secure = React.lazy(() => import('../pages/Loans'));
const Admin = React.lazy(() => import('../pages/Admin/Admin'));
const LoyaltyEnrollment = React.lazy(
  () => import('../pages/LoyaltyEnrollment'),
);
const ChatAI = React.lazy(() => import('../pages/ChatAI/ChatAI'));
const UserProfile = React.lazy(() => import('../pages/UserProfilePage'));

const AuthProtectedRoutes = (): JSX.Element => {
  const { instance } = useMsal();
  const { result, acquireToken } = useMsalAuthentication(
    InteractionType.Redirect,
    { domainHint: process.env.DOMAIN_HINT },
  );

  const isAuthenticated = useIsAuthenticated();

  const { setToken } = useAppContext();

  useEffect(() => {
    if (!isAuthenticated) return;

    const obtainIdToken = async () => {
      await instance.initialize();
      const tokensData = await acquireToken();
      if (tokensData?.idToken) {
        setToken(tokensData?.idToken);
      }
    };

    obtainIdToken();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result?.idToken, isAuthenticated]);

  return (
    <AuthenticatedTemplate>
      <React.Suspense fallback={<Loader showLoader={true} />}>
        <Switch>
          <Route exact path="/" render={(): JSX.Element => <Dashboard />} />
          <Route
            exact
            path={APP_ROUTE_MAIN_PATHS.SEARCH}
            render={(): JSX.Element => <SearchResults />}
          />
          <Route
            path={APP_ROUTE_MAIN_PATHS.CATALYST}
            render={(): JSX.Element => <Catalyst />}
          />
          <Route
            path={APP_ROUTE_MAIN_PATHS.LOANS}
            render={(): JSX.Element => <Secure />}
          />
          <Route
            path={APP_ROUTE_MAIN_PATHS.ADMIN}
            render={(): JSX.Element => <Admin />}
          />
          <Route
            path={APP_ROUTE_MAIN_PATHS.PROGRAM_ENROLLMENT}
            render={(): JSX.Element => <LoyaltyEnrollment />}
          />
          <Route
            path={APP_ROUTE_MAIN_PATHS.CHAT_AI}
            render={(): JSX.Element => <ChatAI />}
          />
          <Route
            path={APP_ROUTE_MAIN_PATHS.USER_PROFILE}
            render={(): JSX.Element => <UserProfile />}
          />
          <Route path="*" render={(): JSX.Element => <Page404 />} />
        </Switch>
      </React.Suspense>
    </AuthenticatedTemplate>
  );
};

export default AuthProtectedRoutes;
